import React from 'react';
import './Contact.css'; // Make sure the path is correct
import linkedInLogo from './linkedin-logo.png'; // Make sure to add this SVG to your project

export default function Contact() {
  return (
    <section className="contact-section">
      <div className="contact-content">
        <div className="contact-text">hello@info-com.io</div>
        <a href="https://www.linkedin.com/company/infocom-engines/" target="_blank" rel="noopener noreferrer" className="linkedin-link">
          <img src={linkedInLogo} alt="LinkedIn" className="linkedin-logo" />
        </a>
      </div>
    </section>
  );
}