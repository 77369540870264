import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/navbar.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Logo from './logo_no_bg_black.svg'; 

function Header() {
  return (
      <Navbar expand="lg" className="navbar-custom">
        <Container className="navbar-container">
          <Navbar.Brand href="/" className="navbar-brand-custom">
            <img src={Logo} alt="Logo" className="navbar-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" activeClassName="active">
            <Nav className="nav-links-custom">
              <Nav.Link as={Link} to="/" className="nav-link-custom" activeClassName="active">Home</Nav.Link>
              <Nav.Link as={Link} to="/about" className="nav-link-custom" activeClassName="active">About</Nav.Link>
              <Nav.Link as={Link} to="/products" className="nav-link-custom" activeClassName="active">Products</Nav.Link>
              <Nav.Link as={Link} to="/contact" className="nav-link-custom" activeClassName="active">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Header;
