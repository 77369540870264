import React, { useState } from 'react';
import styles from './Products.module.css';
import financialPortfolioLogo from '../assets/poly.svg';
import financialPortfolioPreview1 from '../assets/financial-portfolio-preview1.png';
import financialPortfolioPreview2 from '../assets/financial-portfolio-preview2.png';

export default function Products() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const products = [
        {
            name: "financial-portfolio.io",
            logo: financialPortfolioLogo,
            description: "Financial-portfolio.io is your intelligent guide to financial research.<br />" +
                         "Enter a ticker symbol, and it immediately pulls key financial metrics, relevant news, and offers deeper analysis on the market trends impacting the company.",
            url: "https://www.financial-portfolio.io/",
            previews: [financialPortfolioPreview1, financialPortfolioPreview2],
        },
    ];

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % products[0].previews.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + products[0].previews.length) % products[0].previews.length);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.mainTitle}>Our Products</h2>
            
            <div className={styles.productsGrid}>
                {products.map((product, index) => (
                    <div className={styles.productItem} key={index}>
                        <div className={styles.productHeader}>
                            <img src={product.logo} alt={`${product.name} logo`} className={styles.productLogo} />
                            <div className={styles.productInfo}>
                                <h3 className={styles.productName}>{product.name}</h3>
                                <p className={styles.productDescription} dangerouslySetInnerHTML={{ __html: product.description }} />
                            </div>
                        </div>
                        <div className={styles.productPreview}>
                            <button onClick={prevSlide} className={styles.carouselButton}>&lt;</button>
                            <img src={product.previews[currentSlide]} alt={`${product.name} preview`} className={styles.previewImage} />
                            <button onClick={nextSlide} className={styles.carouselButton}>&gt;</button>
                        </div>
                        <a href={product.url} className={styles.productLink} target="_blank" rel="noopener noreferrer">
                            Explore Our Product
                        </a>
                    </div>
                ))}
            </div> 
            
            <div className={styles.videoSection}>
                <h3>Parallelization Capabilities</h3>
                <div className={styles.videoWrapper}>
                    <iframe 
                        width="1440" 
                        height="810" 
                        src="https://www.youtube.com/embed/HUfxmiZ7kGs" 
                        title="Parallelization Capabilities" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                </div>
                <div className={styles.technicalDescription}>
                    <h4>Technical Overview</h4>
                    <ul>
                        <li>Please switch to 1440p and above to view the logs clearly.</li>
                        <li><strong>Tech Stack:</strong> React, Redux, Django, Docker, Celery, Redis</li>
                        <li><strong>Protocols:</strong> WebSocket (for real-time component updates, and to avoid having to wait the load of the entire page) & HTTPS</li>
                        <li><strong>Production Environment:</strong> AWS Beanstalk (for scalability and auto-scaling)</li>
                    </ul>
                    <p>
                        This demo showcases the parallel processing of 4 financial research processes (of course, it can scale way up...), 
                        launched simultaneously via a JS command. The logs demonstrate our application's 
                        use of Celery's prefork pool, with multiple worker processes (ForkPoolWorker-X) 
                        running tasks concurrently across different CPU cores.
                    </p>
                    <p>
                    As you can see, the financial data was fetched almost at the same time. "Almost" because this data was retrieved on a different time depending on where it was located in my DBs.
                    The Financial News data can't appear strictly at the same time for the 4 different tickers because the retrieval time itself varies depending on the complexity of the research that has to be conducted on the Web. 
                    </p>
                </div>
            </div>
        </div>
    );
}