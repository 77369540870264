import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './AboutVision.module.css';
import GraphImage from './Graph.png';
import AuthorImage from './me.jpeg';
import HeroImage from './0_10.png'; // Add this import

export default function AboutPage() {
  return (
    <>
      <Helmet>
        <title>Our Mission - Infocom AI</title>
        <meta name="description" content="Discover Infocom AI's mission to revolutionize web research and our latest breakthroughs in LLM performance using Flow Engineering and LATS." />
      </Helmet>
      <main className={styles.main}>

      <h1 className={styles.h1}>About Us</h1>

        
        <nav className={styles.tableOfContents}>
          <ul>
            <li><a href="#mission">Our Mission</a></li>
            <li><a href="#approach">Our Approach</a></li>
            <li><a href="#services">Our other Services</a></li>
            <li><a href="#research">Latest Research</a></li>
          </ul>
        </nav>

        <div className={styles.heroImageContainer}>
          <img src={HeroImage} alt="Abstract representation of AI and data" className={styles.heroImage} />
        </div>


        <section id="mission" className={styles.section}>
          <h2 className={styles.h2}>Our Mission</h2>
          <p className={styles.p}>
            At Infocom, we develop software that integrates LLMs.
            </p>
            <p className={styles.p}>

            We aim to <strong>augment research</strong>, shifting from traditional search paradigms to more advanced exploratory research models. Our fundamental question is: <em>What is good research?</em>
          </p>
          <p className={styles.p}>
            We aim to develop architectures and algorithms that are both generic and adaptive, ensuring our engines remain stable across various use cases while yielding domain-specific results.
          </p>

        </section>

        <section id="approach" className={styles.section}>
          <h2 className={styles.h2}>Our Approach</h2>
          <p className={styles.p}>
            We leverage technologies including:
          </p>
          <ul className={styles.ul}>
            <li><a href="https://blog.langchain.dev/what-is-an-agent/">Agents</a></li>
            <li>Vector databases</li>
            <li>Large Language Models (LLMs)</li>
            <li>Context-based research techniques</li>
          </ul>
          <p className={styles.p}>
            We believe these technologies are converging to create a new <a href="https://en.wikipedia.org/wiki/General-purpose_technology">General Purpose Technology (GPT)</a>.
          </p>
        </section>

        <section id="services" className={styles.section}>
          <h2 className={styles.h2}>Our other Services</h2>
          <p className={styles.p}>
            Beyond our core research augmentation technology, we offer a range of complementary services:
          </p>
          <ul className={styles.ul}>
            <li>Domain and subdomain registration with SSL/HTTPS setup</li>
            <li>Cloud computing and database solutions</li>
            <li>API development and integration</li>
            <li>LLMOps (Large Language Model Operations)</li>
            <li>Secure user authentication systems</li>
            <li>Integrated payment solutions</li>
          </ul>
        </section>

        <section id="research" className={styles.section}>
          <h2 className={styles.h2}>Latest Research</h2>
          <div className={styles.articleContainer}>
            <img src={GraphImage} alt="Flow Engineering Boosting LLM Performance" className={styles.articleImage} />
            <div className={styles.articleContent}>
              <h3 className={styles.h3}>
                <a href="https://datacortex.notion.site/Flow-Engineering-Boosts-LLMs-Performance-with-LATS-9dcb05de1bea4d8dbc0b696e42fb9b0c" className={styles.titleLink}>
                  Paper Discussion: How Flow Engineering Boosts LLM Performance with LATS
                </a>
              </h3>
              <p className={styles.p}>Using Flow Engineering and LATS, GPT-4's task success rate improved from 67% to 94.4% on the HumanEval benchmark.</p>
              <div className={styles.authorInfo}>
                <img src={AuthorImage} alt="Alex Nesovic" className={styles.authorImage} />
                <span>Alex Nesovic</span>
                <span className={styles.date}>4th July 2024</span>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}