// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Screens/Home';
import Contact from './Screens/Contact';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ReactGA from 'react-ga';
import Products from './Screens/Products';
import AboutPage from './Screens/AboutPage';
import './App.css';
ReactGA.initialize('G-406307293');

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <main className="main-content">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          </main>
          <Footer />
      </div>
    </Router>
  );
};

export default App;